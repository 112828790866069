import styled from 'styled-components';
import { t, Translate } from 'react-i18nify';
import React, { useState, useCallback, useEffect } from 'react';
import { getExt } from '../utils';
import { file2sub, sub2vtt, sub2srt, sub2txt } from '../libs/readSub';
import sub2ass from '../libs/readSub/sub2ass';
import config from '../config.json';

const Style = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20px;
    position: relative;
    overflow: hidden;
    background-color: rgb(0 0 0 / 100%);
    border-left: 1px solid rgb(255 255 255 / 20%);

    .import {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid rgb(255 255 255 / 20%);

        .btn {
            position: relative;
            opacity: 0.85;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;
            width: 48%;
            border-radius: 3px;
            color: #fff;
            cursor: pointer;
            font-size: 13px;
            background-color: #3f51b5;
            transition: all 0.2s ease 0s;

            &:hover {
                opacity: 1;
            }
        }

        .file {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        }
    }

    .burn {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid rgb(255 255 255 / 20%);

        .btn {
            position: relative;
            opacity: 0.85;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;
            width: 100%;
            border-radius: 3px;
            color: #fff;
            cursor: pointer;
            font-size: 13px;
            background-color: #673ab7;
            transition: all 0.2s ease 0s;

            &:hover {
                opacity: 1;
            }
        }
    }

    .export {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid rgb(255 255 255 / 20%);

        .btn {
            position: relative;
            opacity: 0.85;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;
            width: 31%;
            border-radius: 3px;
            color: #fff;
            cursor: pointer;
            font-size: 13px;
            background-color: #009688;
            transition: all 0.2s ease 0s;

            &:hover {
                opacity: 1;
            }
        }
    }

    .operate {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid rgb(255 255 255 / 20%);

        .btn {
            position: relative;
            opacity: 0.85;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;
            width: 48%;
            border-radius: 3px;
            color: #fff;
            cursor: pointer;
            font-size: 13px;
            background-color: #009688;
            transition: all 0.2s ease 0s;

            &:hover {
                opacity: 1;
            }
        }
    }

    .translate {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid rgb(255 255 255 / 20%);

        select {
            width: 65%;
            outline: none;
            padding: 0 5px;
            border-radius: 3px;
        }

        .btn {
            opacity: 0.85;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;
            width: 33%;
            border-radius: 3px;
            color: #fff;
            cursor: pointer;
            font-size: 13px;
            background-color: #673ab7;
            transition: all 0.2s ease 0s;

            &:hover {
                opacity: 1;
            }
        }
    }

    .hotkey {
        display: flex;
        justify-content: space-between;
        padding: 10px;

        span {
            width: 49%;
            font-size: 13px;
            padding: 5px 0;
            border-radius: 3px;
            text-align: center;
            color: rgb(255 255 255 / 75%);
            background-color: rgb(255 255 255 / 20%);
        }
    }

    .bottom {
        padding: 10px;
        a {
            display: flex;
            flex-direction: column;
            border: 1px solid rgb(255 255 255 / 30%);
            text-decoration: none;

            .title {
                color: #ffeb3b;
                padding: 5px 10px;
                animation: animation 3s infinite;
                border-bottom: 1px solid rgb(255 255 255 / 30%);
            }

            @keyframes animation {
                50% {
                    color: #00bcd4;
                }
            }

            img {
                max-width: 100%;
            }
        }
    }

    .progress {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        z-index: 9;
        height: 2px;
        background-color: rgb(0 0 0 / 50%);

        span {
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 0;
            height: 100%;
            background-color: #ff9800;
            transition: all 0.2s ease 0s;
        }
    }
`;

export default function Header({
    player,
    waveform,
    newSub,
    undoSubs,
    clearSubs,
    language,
    subtitle,
    setLoading,
    formatSub,
    setSubtitle,
    setProcessing,
    notify,
}) {
    const [lastFfmpegAt, setLastFfmpegAt] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [uid, setUid] = useState(null);
    
    const [fontSize, setFontSize] = useState(20);
    const [fontColor, setFontColor] = useState('#FFFFFF');
    const [backgroundColor, setBackgroundColor] = useState('#000000');
    const [fontFamily, setFontFamily] = useState('Arial');
    const [subtitlePosition, setSubtitlePosition] = useState(5); // Default 10% from bottom
    const [isSubtitleBurning, setIsSubtitleBurning] = useState(false);

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showConfirmVideoDownloadModal, setShowConfirmVideoDownloadModal] = useState(false);
    const [showBurnCompletedModal, setShowBurnCompletedModal] = useState(false);

    const checkBurningStatus = useCallback(() => {
        const url = new URL(window.location.href);
        const uid = url.searchParams.get('uid');
        fetch(`${config.BACKEND_URL_TERMINAL}/checkBurning/${uid}`)
            .then(response => response.json())
            .then(data => {
                setIsSubtitleBurning(data.message);
                if (data.message === 1) {
                    const intervalId = setInterval(() => {
                        fetch(`${config.BACKEND_URL_TERMINAL}/checkBurning/${uid}`)
                            .then(response => response.json())
                            .then(data => {
                                setIsSubtitleBurning(data.message);
                                if (data.message === 0) {
                                    clearInterval(intervalId);
                                    setShowBurnCompletedModal(true);
                                    setLastFfmpegAt(data.video.last_ffmpeg_at);
                                }
                            });
                    }, 5000);
                    return () => clearInterval(intervalId);
                }
            })
            .catch(error => {
                console.error('Error checking burning status:', error);
            });
    }, []);

    useEffect(() => {
        const fetchVideoInfo = () => {
            const url = new URL(window.location.href);
            const uid = url.searchParams.get('uid');
            fetch(config.BACKEND_URL_TERMINAL + '/videoInfo/' + uid)
                .then(response => response.json())
                .then(data => {
                    if (data.fontSize) setFontSize(parseInt(data.fontSize));
                    if (data.fontColor) setFontColor(data.fontColor);
                    if (data.backgroundColor) setBackgroundColor(data.backgroundColor);
                    if (data.fontFamily) setFontFamily(data.fontFamily);
                    if (data.position) setSubtitlePosition(parseInt(data.position));
                    if (data.directory_path) setUid(data.directory_path);
                    if (data.last_ffmpeg_at) setLastFfmpegAt(data.last_ffmpeg_at);
                })
                .catch(error => {
                    console.error('Error fetching video info:', error);
                });
        };
        fetchVideoInfo();

        // Call checkBurningStatus on initial load
        checkBurningStatus();
    }, [checkBurningStatus]);

    const onSubtitleChange = useCallback(
        (event) => {
            const file = event.target.files[0];
            if (file) {
                const ext = getExt(file.name);
                if (['ass', 'vtt', 'srt', 'json'].includes(ext)) {
                    file2sub(file)
                        .then((res) => {
                            clearSubs();
                            setSubtitle(res);
                        })
                        .catch((err) => {
                            notify({
                                message: err.message,
                                level: 'error',
                            });
                        });
                } else {
                    notify({
                        message: `${t('SUB_EXT_ERR')}: ${ext}`,
                        level: 'error',
                    });
                }
            }
        },
        [notify, setSubtitle, clearSubs],
    );


    const saveSubtitleToBackend = useCallback(
        (type) => {
            let text = '';
            switch (type) {
                case 'vtt':
                    text = sub2vtt(subtitle);

                    setIsLoading(true);

                    let formData = new FormData();
                    formData.append('subtitle', text);
                    formData.append('fontSize', fontSize);
                    formData.append('fontColor', fontColor);
                    formData.append('backgroundColor', backgroundColor);
                    formData.append('position', subtitlePosition);
                    formData.append('fontFamily', fontFamily);

                    const url = new URL(window.location.href);
                    const uid = url.searchParams.get('uid');
                    fetch( config.BACKEND_URL_TERMINAL + '/setSubtitle/' + uid, {
                        mode: "no-cors",
                        method: 'POST',
                        body: formData
                    })
                    .then((data) => {
                        console.log('Subtitle successfully sent to backend');
                        setIsLoading(false);
                        // window.location.href = config.BACKEND_URL_TERMINAL + '/redirect/' + uid;
                    });

                    break;
                default:
                    break;
            }
        },
        [subtitle, fontSize, fontColor, backgroundColor, fontFamily, subtitlePosition],
    );

    useEffect(() => {
        const applySubtitleStyles = () => {
            const subtitleBox = document.querySelector('.subtitle-box');
            if (subtitleBox) {
                subtitleBox.style.fontSize = `${fontSize}px`;
                subtitleBox.style.fontWeight = 'bold';
                subtitleBox.style.backgroundColor = `rgba(${parseInt(backgroundColor.slice(1,3), 16)}, ${parseInt(backgroundColor.slice(3,5), 16)}, ${parseInt(backgroundColor.slice(5,7), 16)}, 0.5)`;
                subtitleBox.style.color = fontColor;
                subtitleBox.style.fontFamily = fontFamily;
                subtitleBox.style.height = 'auto';
                subtitleBox.style.height = `${subtitleBox.scrollHeight}px`;
                subtitleBox.style.overflowY = 'hidden';
            }

            const subtitleBoxOuter = document.querySelector('.subtitle');

            if (subtitleBoxOuter) {
                subtitleBoxOuter.style.bottom = `${subtitlePosition}%`;
            }
        };

        applySubtitleStyles();

        const observer = new MutationObserver(applySubtitleStyles);
        observer.observe(document.body, { childList: true, subtree: true });

        return () => observer.disconnect();
    }, [fontSize, fontColor, backgroundColor, fontFamily, subtitlePosition]);

    useEffect(() => {
        const dummyFile = new File([''], 'subtitle.vtt', { type: 'text/plain' });
        onSubtitleChange({ target: { files: [dummyFile] } });
    }, []);

    const adjustSubtitlePosition = (direction) => {
        setSubtitlePosition(prevPosition => {
            const newPosition = direction === 'up' ? prevPosition + 10 : prevPosition - 10;
            return Math.max(0, Math.min(80, newPosition)); // Clamp between 0% and 90%
        });
    };
    
    const downloadVtt = () => {
        const vttUrl = `${config.BACKEND_URL}/storage/videos/${uid}/subtitle.vtt`;
        fetch(vttUrl)
        .then(response => response.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'subtitle.vtt';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })
        .catch(error => {
            console.error('Error downloading VTT file:', error);
            notify({
                message: 'Error downloading VTT file',
                level: 'error',
            });
        });
    };

    const confirmBurn = () => {
        setShowConfirmModal(false);
        setIsLoading(true);

        saveSubtitleToBackend('vtt');

        setTimeout(() => {
            fetch(`${config.BACKEND_URL}/doBurn/${uid}`)
                .then(response => response.json())
                .then(data => {
                    setIsLoading(false);
                    if (data.error === 0) {
                        checkBurningStatus();
                        notify({
                            message: 'Video burning process started successfully',
                            level: 'success',
                        });
                    } else {
                        throw new Error(data.message || 'Failed to start video burning process');
                    }
                })
                .catch(error => {
                    setIsLoading(false);
                    notify({
                        message: error.message,
                        level: 'error',
                    });
                });
        }, 1000);
    };

    const downloadVideo = () => {
        setShowConfirmVideoDownloadModal(false);
        const downloadUrl = `${config.BACKEND_URL_TERMINAL}/storage/videos/${uid}/subtitled.mp4`;
        
        // Create a temporary anchor element
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = downloadUrl;
        a.download = 'subtitled_video.mp4';
        
        // Append to the document body and trigger the download
        document.body.appendChild(a);
        a.click();
        
        // Clean up
        window.URL.revokeObjectURL(downloadUrl);
        document.body.removeChild(a);
        
        notify({
            message: 'Video download started',
            level: 'success',
        });
    };
    
    return (
        <Style className="tool">

            <div className="loading-popup" style={{
                display: isLoading ? 'flex' : 'none',
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999
            }}>
                <div style={{
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '5px',
                    textAlign: 'center'
                }}>
                    <div className="spinner" style={{
                        border: '4px solid #f3f3f3',
                        borderTop: '4px solid #3498db',
                        borderRadius: '50%',
                        width: '40px',
                        height: '40px',
                        animation: 'spin 1s linear infinite',
                        margin: 'auto'
                    }}></div>
                    <style>
                        {`
                            @keyframes spin {
                                0% { transform: rotate(0deg); }
                                100% { transform: rotate(360deg); }
                            }
                        `}
                    </style>
                    <div style={{ marginTop: '10px' }}>Боловсруулж байна... <br /> Та түр лээнэ үү</div>
                </div>
            </div>

            <div className="top">
                <div className="operate" style={{ display: 'block' }}>
                    {isSubtitleBurning ? (
                        <>
                            <div className="loading-bar-container" style={{
                                width: '100%',
                                height: '50px',
                                backgroundColor: '#e0e0e0',
                                borderRadius: '10px',
                                overflow: 'hidden',
                                marginBottom: '10px',
                                position: 'relative'
                            }}>
                                <div className="loading-bar" style={{
                                    width: '100%',
                                    height: '100%',
                                    backgroundImage: 'linear-gradient(45deg, #4CAF50 25%, #45a049 25%, #45a049 50%, #4CAF50 50%, #4CAF50 75%, #45a049 75%, #45a049 100%)',
                                    backgroundSize: '40px 40px',
                                    animation: 'moveStripes 1s linear infinite'
                                }}></div>
                                <div style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: '#000',
                                    fontSize: '12px',
                                    textAlign: 'center',
                                    width: '100%',
                                    fontWeight: 'bold',
                                    textShadow: '1px 1px 2px #fff'
                                }}>
                                    Таны бичлэгийг боловсруулж байна <br />
                                    Та түр үлээнэ үү
                                </div>
                            </div>
                            <style>
                                {`
                                    @keyframes moveStripes {
                                        0% { background-position: 0 0; }
                                        100% { background-position: 40px 0; }
                                    }
                                `}
                            </style>
                        </>
                    ) : (
                        <div className="btn download_video" onClick={() => setShowConfirmModal(true)}  style={{ backgroundColor: '#0d6efd', width: '100%' }}>
                            Save & Burn
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                viewBox="0 0 16 16"
                                style={{ marginLeft: '5px', verticalAlign: 'middle' }}
                            >
                                <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12z"/>
                                <path d="M3 5a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V5zm1 0v6h8V5H4z"/>
                                <path d="M6 7.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                        </div>
                    )}
                </div>
                {lastFfmpegAt ? (
                <div className="operate download_subtitled_video" >
                    <div className="btn" onClick={() => setShowConfirmVideoDownloadModal(true)} style={{ backgroundColor: '#00b93e', width: '100%' }}>
                        Download video
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                            style={{ marginLeft: '5px', verticalAlign: 'middle' }}
                        >
                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                        </svg>
                    </div>
                </div>
                ) : (
                    <>
                        <center><i style={{fontSize: '11px'}}>Бичлэгтээ Subtitle-аа хадсаны дараа татах боломжтой.</i></center>
                        <div className="operate download_subtitled_video" >
                            <div className="btn" style={{ backgroundColor: '#bbbbbb', width: '100%', cursor: 'not-allowed' }}>
                                Download video
                            </div>
                        </div>
                    </>
                )}
                <div className="operate download_vtt" >
                    <div className="btn" onClick={downloadVtt} style={{ backgroundColor: '#680281', width: '100%' }}>
                        Download VTT file
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                            style={{ marginLeft: '5px', verticalAlign: 'middle' }}
                        >
                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                        </svg>
                    </div>
                </div>
                <div className="info-alert-box">
                    <center>
                        <div style={{
                            backgroundColor: '#1e2a38',
                            padding: '10px',
                            marginTop: '10px',
                            marginBottom: '10px',
                            textAlign: 'center',
                            color: '#e0e0e0'
                        }}>
                             Тохиргоо
                        </div>
                    </center>
                </div>
                <div className="font-family-selector" style={{ borderBottom: '1px solid #5b5b5b', paddingBottom: '10px' }}>
                    <center>
                        Үсэгний загвар сонгох <br />
                        <select 
                            value={fontFamily} 
                            onChange={(e) => setFontFamily(e.target.value)}
                            style={{ 
                                marginTop: '5px', 
                                padding: '5px', 
                                borderRadius: '5px',
                                backgroundColor: '#2c3e50',
                                color: '#ecf0f1',
                                border: '1px solid #34495e'
                            }}
                        >
                            <option value="Sans">Sans</option>
                            <option value="NotoSerif">Noto Serif</option>
                            <option value="Monospace">Monospace</option>
                            <option value="Arial">Arial</option>
                            <option value="Helvetica">Helvetica</option>
                            <option value="Times">Times</option>
                            <option value="Courier">Courier</option>
                            <option value="DejaVuSans">DejaVu Sans</option>
                            <option value="DejaVuSerif">DejaVu Serif</option>
                            <option value="LiberationSans">Liberation Sans</option>
                        </select>
                    </center>
                </div>
                <div className="color-picker" style={{ borderBottom: '1px solid #5b5b5b', paddingBottom: '10px' }}>
                    <center>
                        Үсэгний өнгө <br />
                        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '5px', marginTop: '5px' }}>
                            {['#FFFFFF', '#000000', '#0032FF', '#2CC22C', '#F30000', '#FF1493', '#9400D3', '#FFEC3C'].map((color) => (
                                <button
                                    key={color}
                                    style={{
                                        width: '30px',
                                        height: '30px',
                                        backgroundColor: color,
                                        border: fontColor.toUpperCase() === color ? '5px solid green' : '1px solid #ccc',
                                        borderRadius: '50%',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => setFontColor(color)}
                                />
                            ))}
                        </div>
                    </center>
                </div>
                <div className="background-color-picker" style={{ borderBottom: '1px solid #5b5b5b', paddingBottom: '10px'}}>
                    <center>
                        Үсэгний арын дэвсгэрийн өнгө <br />
                        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '5px', marginTop: '5px' }}>
                            {['#FFFFFF', '#000000', '#0032FF', '#2CC22C', '#F30000', '#FF1493', '#9400D3', '#FFEC3C'].map((color) => (
                                <button
                                    key={color}
                                    style={{
                                        width: '30px',
                                        height: '30px',
                                        backgroundColor: color,
                                        border: backgroundColor.toUpperCase() === color ? '5px solid green' : '1px solid #ccc',
                                        borderRadius: '50%',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => setBackgroundColor(color)}
                                />
                            ))}
                        </div>
                    </center>
                </div>
                <div className="font-size-picker" style={{ borderBottom: '1px solid #5b5b5b', paddingBottom: '10px'}}>
                    <center>
                        Үсэгний хэмжээ <br />
                        <input
                            type="range"
                            min="15"
                            max="26"
                            value={fontSize}
                            onChange={(e) => {
                                const newSize = parseInt(e.target.value);
                                setFontSize(newSize);
                            }}
                        />
                        <span>{fontSize}px</span>
                    </center>
                </div>
                <div className="font-position-picker">
                    <center>
                        Subtitle байрлал <br />
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', marginTop: '5px' }}>
                            <button onClick={() => adjustSubtitlePosition('up')} style={arrowButtonStyle}>
                                ▲
                            </button>
                            <span>{subtitlePosition}% from bottom</span>
                            <button onClick={() => adjustSubtitlePosition('down')} style={arrowButtonStyle}>
                                ▼
                            </button>
                        </div>
                    </center>
                </div>
            </div>

            <div className="bottom">
                <div className="operate back">
                    <div className="btn" style={{ backgroundColor: '#ffffff', color: '#000000', width: '100%' }} onClick={() => {
                        const urlParams = new URLSearchParams(window.location.search);
                        const uid = urlParams.get('uid');
                        window.location.href = config.BACKEND_URL_TERMINAL + '/user';
                    }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-chevron-left"
                            viewBox="0 0 16 16"
                        >
                            <path
                                fillRule="evenodd"
                                d="M9.354 12.354a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L5.707 8l3.647 3.646a.5.5 0 0 1 0 .708z"
                            />
                        </svg>
                        Профайлруу буцах
                    </div>
                </div>

                <div className="operate" style={{ borderBottom: 'none' }}>
                    <div
                        className="btn"
                        onClick={() => {
                            if (window.confirm(t('CLEAR_TIP')) === true) {
                                clearSubs();
                                window.location.reload();
                            }
                        }}
                    >
                        Reset
                    </div>
                    <div className="btn" onClick={undoSubs}>
                        <Translate value="UNDO" />
                    </div>
                </div>
                <center>
                    <i style={{fontSize: '11px'}}>Space: Бичлэг тоглуулна зогсооно</i><br/>
                    <i style={{fontSize: '11px'}}>Ctrl + Z: Үйлдэл буцаана</i>
                </center>
            </div>

            {showConfirmModal && (
                <div className="modal" style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 9999
                }}>
                    <div style={{
                        backgroundColor: 'white',
                        padding: '20px',
                        borderRadius: '5px',
                        maxWidth: '80%',
                        textAlign: 'center'
                    }}>
                        <h3 style={{ color: 'black' }}>Анхааруулга</h3>
                        <p style={{ color: 'black' }}>
                            Та Subtitle засах бүх үйлдлээ хийсэн гэж үзвэл энэ үйлдлийг хийгээрэй.
                        </p>
                        <p style={{ color: 'black' }}>
                            Бичлэгийн хэмжээнээс хамаарч 1-5 минут үргэлжилж болзошгүй.
                        </p>
                        <p style={{ color: 'black' }}>Боловсруулж дууссаны дараа татах линк гарч ирэх болно. <br /> Мөн имэйл хаягаар татах линк илгээгдэнэ.</p>
                        <div style={{ marginTop: '20px' }}>
                            <button onClick={() => setShowConfirmModal(false)} style={{
                                backgroundColor: '#f44336',
                                color: 'white',
                                padding: '10px 20px',
                                border: 'none',
                                borderRadius: '5px',
                                marginRight: '10px',
                                cursor: 'pointer'
                            }}>Цуцлах</button>
                            <button onClick={confirmBurn} style={{
                                backgroundColor: '#4CAF50',
                                color: 'white',
                                padding: '10px 20px',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer'
                            }}>Эхлүүлэх</button>
                        </div>
                    </div>
                </div>
            )}

            {showBurnCompletedModal && (
                <div className="modal" style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 9999
                }}>
                    <div style={{
                        backgroundColor: 'white',
                        padding: '20px',
                        borderRadius: '5px',
                        maxWidth: '80%',
                        textAlign: 'center'
                    }}>
                        <h3 style={{ color: 'black' }}>Бичлэгийг боловсруулж дууслаа</h3>
                        <p style={{ color: 'black' }}>
                            Таны бичлэг амжилттай боловсруулагдлаа. Та одоо бичлэгээ татаж авах боломжтой.
                        </p>
                        <p style={{ color: 'black' }}>
                            Мөн таны имэйл хаяг руу татах холбоос илгээгдсэн болно.
                        </p>
                        <div style={{ marginTop: '20px' }}>
                            <button onClick={() => setShowBurnCompletedModal(false)} style={{
                                backgroundColor: '#4CAF50',
                                color: 'white',
                                padding: '10px 20px',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer'
                            }}>Ойлголоо</button>
                        </div>
                    </div>
                </div>
            )}

            {showConfirmVideoDownloadModal && (
                <div className="modal" style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 9999
                }}>
                    <div style={{
                        backgroundColor: 'white',
                        padding: '20px',
                        borderRadius: '5px',
                        maxWidth: '80%',
                        textAlign: 'center'
                    }}>
                        <h3 style={{ color: 'black' }}>Анхааруулга</h3>
                        <p style={{ color: 'black' }}>
                            Хэрэв та Subtitle даа өөрчлөлт хийсэн бол Subtitle аа бичлэгрүү дахин хадсаны дараа татаж авах хэрэгтэй.
                        </p>
                        <p style={{ color: 'black' }}>
                            <b>Хамгийн сүүлд Subtitle хадсан огноо: {lastFfmpegAt}</b>
                        </p>
                        <div style={{ marginTop: '20px' }}>
                            <button onClick={() => setShowConfirmVideoDownloadModal(false)} style={{
                                backgroundColor: '#f44336',
                                color: 'white',
                                padding: '10px 20px',
                                border: 'none',
                                borderRadius: '5px',
                                marginRight: '10px',
                                cursor: 'pointer'
                            }}>Цуцлах</button>
                            <button onClick={downloadVideo} style={{
                                backgroundColor: '#4CAF50',
                                color: 'white',
                                padding: '10px 20px',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer'
                            }}>Бичлэгийг татах</button>
                        </div>
                    </div>
                </div>
            )}

        </Style>
    );
}

const arrowButtonStyle = {
    width: '30px',
    height: '30px',
    backgroundColor: '#2c3e50',
    color: '#ecf0f1',
    border: '1px solid #34495e',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};